<template>
  <div class="assembly-pagination">
    <el-pagination
        style="text-align: right; margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="Number($store.state.initPageSize)"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "assembly-pagination",
  props:['currentPage','total'],
  data(){
    return{

    }
  },
  methods:{
    handleSizeChange(val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    },
  }
}
</script>

<style lang="scss">
.assembly-pagination{

}
</style>