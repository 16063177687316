<template>
   <div class="assembly-dialog">
      <el-dialog :title="title" :visible.sync="visible" :width="width" :top="top" :close-on-click-modal="$store.state.closeModelWithShadow" :close-on-press-escape="$store.state.closeModelWithShadow" :before-close="handleBeforeClose" :destroy-on-close="destroyOnClose" :append-to-body="appendToBody" v-on="$listeners">
         <slot></slot>
         <span slot="footer">
            <slot name="footer"></slot>
         </span>
      </el-dialog>
   </div>
</template>

<script>
export default {
   name: "assembly-dialog",
   props: {
      id: {
         type: String,
         default: () => {
            return ''
         }
      },
      title: {
         type: String,
         default: () => {
            return '标题'
         }
      },
      visible: {
         type: Boolean,
         default: () => {
            return false
         }
      },
      width: {
         type: String,
         default: () => {
            return '30%'
         }
      },
      top: {
         type: String,
         default: () => {
            return '15vh'
         }
      },
      destroyOnClose: {
         type: Boolean,
         default: () => {
            return false
         }
      },
      appendToBody: {
         type: Boolean,
         default: () => {
            return false
         }
      }
   },
   methods: {
      handleBeforeClose() {
         if (this.$store.state.closeModel) {
            this.$confirm('关闭后数据不会保存,确认关闭？')
               .then(_ => {
                  this.$emit('before-close', this.id)
               }).catch(_ => {
               });
         } else {
            this.$emit('before-close', this.id)
         }
      }
   },

}
</script>

<style lang="scss">
.assembly-dialog {
}
</style>
