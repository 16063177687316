<template>
  <div class="search-open-btn">
    <el-button class="searchOpenBtn" type="text" :size="$store.state.size" :icon="searchOpenIcon"
               @click="searchOpen">{{ searchShow ? '收起' : '展开' }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "search-open-btn",
  props:{
    searchShow:{
      type: Boolean,
      default:()=>{
        return false
      }
    }
  },
  data() {
    return {
      //搜索区域展开收起图标
      searchOpenIcon: 'el-icon-arrow-down',
    }
  },
  methods: {
    searchOpen() {
      this.searchOpenIcon = !this.searchShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
      this.$emit("searchOpen", !this.searchShow)
    }
  }
}
</script>

<style lang="scss">
//搜索展开收起按钮位置设置
.search-open-btn{
  position: absolute;
  right:25px;
}
</style>
