<template>
  <div class="interface">
    <div ref="search" class="search">
      <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
        <el-form :inline="true" :model="searchForm" label-width="80px">
          <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
          <el-form-item label="接口类型">
            <el-select v-model="searchForm.sysIiType" :size="$store.state.size" placeholder="接口类型" clearable>
              <el-option v-for="(item, index) of this.interfaceTypeList" :key="index" :label="item.remarks"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="制造商">
            <el-select v-model="searchForm.sysIiCustomerId" :size="$store.state.size" placeholder="供应商" clearable>
              <el-option v-for="e in supplierList" :key="e.sysCiId" :label="e.sysCiShortName"
                         :value="e.sysCiId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接口地址">
            <el-input v-model="searchForm.sysIiUrl" :size="$store.state.size" placeholder="接口地址" clearable
                      @keyup.enter.native="searchSubmit(true)"></el-input>
          </el-form-item>
          <el-form-item label="启用状态">
            <el-select v-model="searchForm.sysIiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
              <el-option label="启用" value="0"></el-option>
              <el-option label="停用" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接口效期" v-show="searchShow">
            <el-date-picker
                v-model="searchForm.sysIiValidityPeriod"
                :size="$store.state.size"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                clearable>
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-button v-if="hasPermission('2-7-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)"
                   icon="el-icon-search">查询
        </el-button>
        <el-button v-if="hasPermission('2-7-b')" type="default" plain :size="$store.state.size" @click="resetSubmit"
                   icon="el-icon-delete">清空
        </el-button>
        <el-button v-if="hasPermission('2-7-c')" type="primary" :size="$store.state.size" @click="saveOpen"
                   icon="el-icon-circle-plus-outline">新增
        </el-button>
      </el-card>
    </div>
    <el-table
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        @sort-change="sortChange"
        border
        :size="this.$common.tableLineHeight()"
        :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}"
        style="width: 100%; margin-top: 10px">
      <el-table-column
          prop="interfaceType"
          align="center"
          sortable
          label="接口类型">
      </el-table-column>
      <el-table-column
          prop="customer"
          align="center"
          sortable
          label="制造商">
      </el-table-column>
      <el-table-column
          prop="sysIiUrl"
          align="center"
          sortable
          label="接口地址">
      </el-table-column>
      <el-table-column
          prop="sysIiStatus"
          align="center"
          sortable
          label="启用状态">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.sysIiStatus"
              @change="changeBtn(scope.row)"
              :active-value="0"
              :inactive-value="1"
              active-text="启用"
              inactive-text="停用">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="sysIiValidityPeriod"
          align="center"
          sortable
          label="接口效期">
          <template slot-scope="scope"> {{ _setTime(scope.row.sysIiValidityPeriod) }} </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="操作"
          width="260">
        <template slot-scope="scope">
          <el-button v-if="hasPermission('2-7-d')" type="text" size="small" @click="seeOpen(scope.row)"
                     icon="el-icon-document">查看
          </el-button>
          <template v-if="!(scope.row.customer == '海昌' || scope.row.customer == '万新' || scope.row.customer == '阿波罗')">
            <el-button v-if="hasPermission('2-7-e')" type="text" size="small" @click="editOpen(scope.row)"
                       icon="el-icon-edit-outline">编辑
            </el-button>
            <el-button v-if="hasPermission('2-7-f')" type="text" size="small" @click="delOpen(scope.row)"
                       icon="el-icon-delete">删除
            </el-button>
          </template>

        </template>
      </el-table-column>
    </el-table>
    <pagination
        :page-size='currentPageSize'
        :total='total'
        :current-page="currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
    </pagination>


    <!--    保存模态框-->
    <assembly-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisibleSave"
        width="30%"
        @before-close="handleClose">
      <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
        <el-form-item label="接口类型" prop="sysIiType">
          <el-select v-model="saveForm.sysIiType" :size="$store.state.size" placeholder="接口类型" clearable>
            <el-option v-for="(item, index) of this.interfaceTypeList" :key="index" :label="item.remarks"
                       :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制造商" prop="sysIiCustomerId">
          <el-select v-model="saveForm.sysIiCustomerId" :size="$store.state.size" placeholder="供应商" clearable>
            <el-option v-for="e in supplierList" :key="e.sysCiId" :label="e.sysCiShortName"
                       :value="e.sysCiId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口地址" prop="sysIiUrl">
          <el-input v-model="saveForm.sysIiUrl" :size="$store.state.size" placeholder="接口地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="接口描述" prop="sysIiRemarks">
          <el-input v-model="saveForm.sysIiRemarks" :size="$store.state.size" placeholder="接口描述" clearable></el-input>
        </el-form-item>
        <el-form-item label="接口效期" prop="sysIiValidityPeriod">
          <el-date-picker
              v-model="saveForm.sysIiValidityPeriod"
              :size="$store.state.size"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="启用状态" prop="sysIiStatus">
          <el-select v-model="saveForm.sysIiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
            <el-option label="启用" :value="0"></el-option>
            <el-option label="停用" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
        <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
      </span>
    </assembly-dialog>
    <!--    查看模态框-->
    <assembly-dialog
        title="查看"
        :visible.sync="dialogVisibleSee"
        width="30%"
        @before-close="seeClose"
    >
      <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border :labelStyle="{ width:'100px' }">
        <el-descriptions-item>
          <template slot="label">
            接口类型
          </template>
          {{ seeForm.interfaceType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            制造商
          </template>
          {{ seeForm.customer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            接口地址
          </template>
          {{ seeForm.sysIiUrl }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            启用状态
          </template>
          {{ seeForm.sysIiStatus === 0 ? '启用' : '停用' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            接口效期
          </template>
          {{ seeForm.sysIiValidityPeriod }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
      </span>
    </assembly-dialog>
  </div>
</template>

<script>
import { timeFormat } from '@/utils/timeFormat'
import searchOpenBtn from '@/components/assembly-search/search-open-btn/index'
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
  name: "interface",
  components: {
    AssemblyDialog,
    searchOpenBtn,
    pagination
  },
  watch: {
    //监听头部展开收起变化
    '$store.state.isHeader'() {
      this.setTableHeight()
    },
    //监听快速导航展开收起变化
    '$store.state.fastNav'() {
      this.setTableHeight()
    },
    //监听搜索区域变化改变列表
    searchShow() {
      this.setTableHeight()
    },
  },
  data() {
    return {
      currentPageSize: 10,
      dialogTitle:'新增',
      total:0,
      //搜索区域展开收起开关
      searchShow: false,
      //搜索区域展开收起图标
      searchOpenIcon: 'el-icon-arrow-down',
      //表格高度
      tableHeight: 0,
      //加载
      tableLoading: false,
      //表格数据
      tableData: [],
      //供应商列表
      supplierList: [],
      //接口类型列表
      interfaceTypeList: [],
      //当前页面
      currentPage: 1,
      //搜索表单
      searchForm: {
        sysIiCustomerId: '',
        sysIiType: '',
        sysIiUrl: '',
        sysIiStatus: '',
        sysIiValidityPeriod: '',
        orderByColumn: '',
        order: '',
        page: 1,
        pageSize: this.$store.state.initPageSize
      },
      //保存模态框开关
      dialogVisibleSave: false,
      //保存表单
      saveForm: {
        sysIiId: '',
        sysIiCustomerId: '',
        sysIiType: '',
        sysIiUrl: '',
        sysIiStatus: 0,
        sysIiValidityPeriod: '',
        sysIiRemarks: '',
      },
      //保存表单验证
      saveRules: {
        sysIiType: [
          {required: true, message: '请选择接口类型', trigger: 'change'}
        ],
        sysIiCustomerId: [
          {required: true, message: '请选择供应商', trigger: 'change'}
        ],
        sysIiUrl: [
          {required: true, message: '请输入接口地址', trigger: 'blur'}
        ],
        sysIiStatus: [
          {required: true, message: '请选择启用状态', trigger: 'blur'}
        ],
        sysIiValidityPeriod: [
          {required: true, message: '请选择接口效期', trigger: 'change'}
        ],
        sysIiRemarks: [
          {required: true, message: '请选择接口描述', trigger: 'blur'}
        ]
      },
      //查看模态框开关
      dialogVisibleSee: false,
      seeForm: {
        interfaceType: '',
        customer: '',
        sysIiUrl: '',
        sysIiStatus: '',
        sysIiValidityPeriod: ''
      },
    }
  },
  mounted() {
    //初始化计算表格区域高度
    this.setTableHeight();
    this.getInterfaceType()
    this.searchSubmit();
    this.supplier();
  },
  methods: {
    _setTime(val) {
      return timeFormat(val)
    },
    //判断权限是否存在
    hasPermission(item) {
      return this.$common.hasPermission(item);
    },
    /****表格高度计算 start********************************************************************************/
    //计算表格区域高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
        window.onresize = () => {
          return (() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
          })();
        };
      })
    },
    /****表格高度计算 end********************************************************************************/
    /****搜素区域 start********************************************************************************/
    //搜索展开收起事件
    searchOpen(searchShow) {
      this.searchShow = searchShow
    },
    //供应商列表
    supplier() {
      this.$server.getList({sysCiType: 1}).then((res) => {
        if (res.ok) {
          this.supplierList = res.data

        }
      }).catch(e => {
        console.log(e)
      })
    },
    //搜索表单提交
    searchSubmit(flag) {
      if (flag) {
        this.currentPage = 1
        this.searchForm.page = 1
        this.searchForm.pageSize = this.currentPageSize
      }
      this.tableLoading = true
      this.$server.getInterfaceInfo(this.searchForm).then((res) => {
        this.tableLoading = false
        if (res.ok) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      }).catch(e => {
        this.tableLoading = false
        console.log(e)
      })
    },
        _resetPage() {
      this.currentPage = 1,
      this.searchForm.page = 1
    },

    //搜索表单重置
    resetSubmit() {
      /*      this.$common.clearFormValue(this.searchForm)
            this.$alert(this.searchForm, '请求数据', {
              confirmButtonText: '确定'
            });*/
      this.$common.clearFormValue(this.searchForm)
      this.searchForm.page = 1;
      this.searchForm.pageSize = this.currentPageSize
      this.searchSubmit()
      this.currentPage = 1
    },
    /****搜素区域 end********************************************************************************/
    /****保存区域 start********************************************************************************/
    //保存表单提交
    saveOpen() {
      this.saveForm = {
                sysIiId: '',
        sysIiCustomerId: '',
        sysIiType: '',
        sysIiUrl: '',
        sysIiStatus: 0,
        sysIiValidityPeriod: '',
        sysIiRemarks: '',
       }
      this.dialogTitle ='新增'
      this.dialogVisibleSave = true
    },
    //模态框关闭前回调
    handleClose() {
        this.dialogVisibleSave = false
        this.clearForm('saveForm')
    },
    //保存提交
    saveSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$server.saveInterfaceInfo(this.saveForm).then(res => {
            if (res.ok) {
                            this._resetPage()
              this.$message.success(res.msg)
              this.searchSubmit()
            } else {
              this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            this.clearForm('saveForm')
          }).catch(e => {
            console.log(e)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //清空表单
    clearForm(formName) {
      this.$refs[formName].resetFields();
      console.log(this[formName])
    },
    //编辑模态框开启
    editOpen(row) {
      this.dialogTitle ='编辑'
      this.dialogVisibleSave = true;
      this.$nextTick(() => {
        this.saveForm = JSON.parse(JSON.stringify(row))
      })
    },
    //停启用按钮
    changeBtn(formName) {
      this.$server.saveInterfaceInfo(formName).then(res => {
        if (res.ok) {
          this.$message.success(res.msg)
          this.searchSubmit(false, 'searchForm');
          console.log("1")
        } else {
          this.$message.error(res.msg)
        }
        this.dialogVisibleSave = false
        this.$common.clearFormValue(this.saveForm)
      }).catch(e => {
        console.log(e)
      })
    },
    /****保存区域 end********************************************************************************/
    /****查看区域 start********************************************************************************/
    seeOpen(row) {
      this.seeForm = row
      console.log('查看区域==', JSON.stringify(row))
      this.dialogVisibleSee = true
    },
    seeClose() {
      this.dialogVisibleSee = false
    },
    /****查看区域 end********************************************************************************/
    /****删除区域 start********************************************************************************/
    delOpen(row) {
      this.$confirm('确认删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.removeInterfaceInfo({id: row.sysIiId}).then(res => {
          if (res.ok) {
            this.$message.success(res.msg)
            this.searchSubmit()
          } else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          console.log(e)
        })
      }).catch(() => {
      });
    },
    /****删除区域 end********************************************************************************/
    handleSizeChange(val) {
      this.currentPage = 1
      this.currentPageSize = val
      this.searchForm.page = 1
      this.searchForm.pageSize = val
      this.searchSubmit()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.searchForm.page = val
      this.searchSubmit()
    },
    //排序
    sortChange(row) {
      console.log('排序排序排序', JSON.stringify(row))
      if (row.prop == 'interfaceType') {
        this.searchForm.orderByColumn = 'sysCiId'
      } else {
        this.searchForm.orderByColumn = row.prop
      }
      if (row.order == 'ascending') {
        this.searchForm.order = 'asc'
      } else if (row.order == 'descending') {
        this.searchForm.order = 'desc'
      } else {
        this.searchForm.order = ''
      }
      this.searchSubmit()
      this.searchForm.page = 1;
    },
    getInterfaceType() {
      this.$server.getInterfaceType().then((res) => {
        if (res.ok) {
          this.interfaceTypeList = res.data

        }
      }).catch(e => {
        console.log(e)
      })
    },
    onSubmit() {
      console.log('submit!');
    }
  },
}
</script>

<style lang="scss">
.interface {
  .search {
    //搜索展开收起按钮位置设置
    .searchOpenBtn {
      float: right;
    }

    //表单每行底部外边距设置
    .el-form-item {
      margin-bottom: 10px;
    }

    .el-select {
      width: 200px;
    }

    .el-input {
      width: 200px;
    }

    //分割线外边距设置
    .el-divider--horizontal {
      margin: 0 0 10px 0;
    }
  }

  .el-dialog {
    .el-select {
      width: 100%;
    }

    .el-input {
      width: 100%;
    }
  }
}
</style>

<style scoped>
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>
